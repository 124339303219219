<template>
  <v-dialog v-model="dialog" max-width="450px">
    <v-card>
      <v-col cols="12">
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialog = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>

        <v-card-title>
          <span class="headline">Order</span>
        </v-card-title>
        <v-card-text>
          <v-container style="background-color: whitesmoke">
            <v-form ref="CustomerForm">
              <v-row>
                <v-col cols="12" style="">
                  <v-text-field
                    v-model="orderNumber"
                    label="Order number"
                    clearable
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-row class="px-7">
          <v-btn color="secondary" dark tile @click="dialog = false">
            <v-card-text class="black--text font-weight-bold">
              Skip
            </v-card-text>
          </v-btn>
          <v-spacer />
          <v-btn color="primary" dark tile @click="addCustomer">
            <v-card-text class="black--text font-weight-bold">
              Add Order Details
            </v-card-text>
          </v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Customer",
  props: ["show", "id"],

  data: () => ({
    customers: [],
    orderNumber: "",
  }),

  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("close", val);
      },
    },
  },

  methods: {
    addCustomer() {
      this.$emit("change", this.orderNumber);
      this.dialog = false;
    },
  },
};
</script>