<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-width: 300px;
    "
  >
    <div
      style="
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        padding-bottom: 0.25rem;
        text-transform: uppercase;
      "
    >
      {{ company.companyName }}
    </div>
    <div style="font-size: 1.125rem; line-height: 1.75rem">
      ABN {{ company.abn }}
    </div>

    <div
      style="
        font-size: 1.125rem;
        line-height: 1.75rem;
        text-transform: uppercase;
      "
    >
      <p><span v-html="company.address"></span></p>
    </div>
    <!-- <div style="font-size: 1.125rem; line-height: 1.75rem">
      AGNES BANKS NSW 2753
    </div> -->

    <div style="font-size: 1.125rem; line-height: 1.75rem">
      PHONE {{ company.phoneNumber }}
    </div>
    <div style="font-size: 1.125rem; line-height: 1.75rem">
      DATE {{ dateFormat(bill.createdAt) }}
    </div>
    <table style="table-layout: auto; padding-top: 0.25rem">
      <thead>
        <tr>
          <th style="font-size: 1.125rem; line-height: 1.75rem">MDSE RETURN</th>
          <th style="font-size: 1.125rem; line-height: 1.75rem">*******</th>
          <th style="font-size: 1.125rem; line-height: 1.75rem">*******</th>
        </tr>
      </thead>
      <tbody v-if="bill && bill.billProducts">
        <tr v-for="(product, i) in bill.billProducts" :key="i">
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: start"
          >
            {{
              product.unitQty && product.unitQty > 0
                ? product.quantity / product.unitQty
                : product.quantity
            }}
            {{ product.unitName }}
          </td>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; padding: 0 1rem"
          >
            {{ product.productDescription }}
          </td>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: end"
          >
            ${{ currencyFormat(product.total) }}
          </td>
        </tr>
        <tr>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: start"
          >
            SUB TOTAL
          </td>
          <td style="font-size: 1.125rem; line-height: 1.75rem"></td>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: end"
          >
            ${{ currencyFormat(subTotal) }}
          </td>
        </tr>
        <tr v-if="bill.extraCharges">
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: start"
          >
            EXTRAS
          </td>
          <td style="font-size: 1.125rem; line-height: 1.75rem"></td>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: end"
          >
            ${{ currencyFormat(bill.extraCharges) }}
          </td>
        </tr>

        <tr v-if="bill.deliveryCharge">
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: start"
          >
            DELIVERY
          </td>
          <td style="font-size: 1.125rem; line-height: 1.75rem"></td>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: end"
          >
            ${{ currencyFormat(bill.deliveryCharge) }}
          </td>
        </tr>
        <tr
          v-if="
            bill.discountTotal && parseFloat(bill.discountTotal).toFixed(2) > 0
          "
        >
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: start"
          >
            DISCOUNT
          </td>
          <td style="font-size: 1.125rem; line-height: 1.75rem"></td>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: end"
          >
            ${{ currencyFormat(bill.discountTotal) }}
          </td>
        </tr>
        <tr>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: start"
          >
            TOTAL
          </td>
          <td style="font-size: 1.125rem; line-height: 1.75rem"></td>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: end"
          >
            ${{ currencyFormat(total) }}
          </td>
        </tr>
        <tr>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: start"
          >
            CASH
          </td>
          <td style="font-size: 1.125rem; line-height: 1.75rem"></td>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: end"
          >
            ${{ currencyFormat(bill.tendered) }}
          </td>
        </tr>
        <tr>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: start"
          >
            BALANCE
          </td>
          <td style="font-size: 1.125rem; line-height: 1.75rem"></td>
          <td
            style="font-size: 1.125rem; line-height: 1.75rem; text-align: end"
          >
            ${{ currencyFormat(balance) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      style="
        padding-top: 0.25rem;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.75rem;
      "
    >
      THANK YOU
    </div>
    <div
      style="
        padding-top: 0.25rem;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.75rem;
      "
    >
      TRADING HOURS
    </div>
    <div style="font-size: 1.125rem; line-height: 1.75rem">
      MON-FRI 8.30 - 5.30
    </div>
    <div style="font-size: 1.125rem; line-height: 1.75rem">
      SATURDAY 8.30 - 4.00
    </div>
    <div style="font-size: 1.125rem; line-height: 1.75rem">SUNDAY CLOSED</div>
    <div style="font-size: 1.125rem; line-height: 1.75rem">
      {{ bill.custCode ? "CLERK " + bill.custCode : "" }}
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "Bill",
  props: ["bill", "customer", "company"],
  computed: {
    subTotal() {
      let amount = 0;
      if (this.bill && this.bill.billProducts) {
        amount = this.bill.billProducts.reduce((total, x) => {
          total += x.total;
          return total;
        }, 0);
      }
      return amount;
    },
    total() {
      return this.bill.billTotal;
    },
    extraChrges() {
      return this.bill.extraChrges || 0;
    },
    deliveryCharge() {
      return this.bill.deliveryCharge || 0;
    },
    balance() {
      return this.bill.tendered - this.bill.billTotal;
    },
  },
  watch: {
    bill: {
      handler() {
        this.bill.billProducts = this.bill.billProducts.map((x) => {
          x.total =
            x.amount *
            (x.unitQty && x.unitQty > 0 ? x.quantity / x.unitQty : x.quantity);
          return x;
        });
      },
    },
  },
  methods: {
    currencyFormat(val) {
      return val ? parseFloat(val).toFixed(2) : 0.0;
    },
    dateFormat(val) {
      return moment.utc(val).local().format("DD/MM/YYYY h:mm:ss a");
    },
  },
};
</script>
