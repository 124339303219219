<template>
  <v-dialog v-model="dialog" max-width="450px">
    <v-card>
      <v-col cols="12">
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialog = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>

        <v-card-title>
          <span class="headline">Customer</span>
        </v-card-title>
        <v-card-text>
          <v-container style="background-color: whitesmoke">
            <v-form ref="CustomerForm">
              <v-row>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-autocomplete
                    v-model="customer"
                    :items="customers"
                    :loading="isLoading"
                    :search-input.sync="searchName"
                    hide-no-data
                    @change="setCustomer()"
                    label="Search Customer"
                    item-text="firstName"
                    item-value="customerId"
                    placeholder="Start typing"
                    prepend-icon="mdi-account-search"
                    return-object
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="customer.firstName"
                    label="First name"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="customer.lastName"
                    label="Last name"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="customer.telephone"
                    label="Phone"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="customer.mobile"
                    label="Mobile"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="customer.email"
                    label="Email"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="customer.address"
                    label="Address"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="customer.city"
                    label="City"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="">
                  <v-text-field
                    v-model="customer.state"
                    label="State"
                    clearable
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-row class="px-7">
          <v-btn color="secondary" dark tile @click="dialog = false">
            <v-card-text class="black--text font-weight-bold">
              {{ negativeBtn }}
            </v-card-text>
          </v-btn>
          <v-spacer />
          <v-btn color="primary" dark tile @click="addCustomer">
            <v-card-text class="black--text font-weight-bold">
              Add Customer Details
            </v-card-text>
          </v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "Customer",
  props: ["show", "data", "negativeBtn"],

  data: () => ({
    customers: [],
    searchName: null,
    searchTimeout: null,
    customer: {
      customerId: "",
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      mobile: "",
      address: "",
      city: "",
      state: "",
    },
    defaultCustomer: {
      customerId: "",
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      mobile: "",
      address: "",
      city: "",
      state: "",
    },
    isLoading: false,
  }),

  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("close", val);
      },
    },
  },
  watch: {
    data(val) {
      if (val) {
        this.customer = Object.assign({}, val);
      }
    },
    searchName(val) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        if (val != null && val != "") {
          var pagination = {
            pageNumber: 1,
            pageSize: 10,
            search: val,
          };
          this.isLoading = true;
          this.$http
            .post("/Customers/Paginated", null, { params: pagination })
            .then((response) => {
              this.customers = response.data.data.items;
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.status) {
                this.$toast.error("Error in getting Data", "Error", {
                  position: "topRight",
                });
              }
            });
        } else {
          this.customers = [];
        }
      }, 1500);
    },
  },
  methods: {
    addCustomer() {
      this.$emit("change", this.customer);
      this.dialog = false;
    },
    setCustomer() {
      if (this.customer == null) {
        this.customer = Object.assign({}, this.defaultCustomer);
      }
      // let customer = this.customers.find(
      //   x => (x.customerId = this.customer.customerId)
      // );
      // this.customer = Object.assign({}, customer);
    },
  },
};
</script>
