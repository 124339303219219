<template>
  <v-dialog v-model="dialog" max-width="450px">
    <v-card>
      <v-col cols="12">
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="dialog = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>

        <v-card-title>
          <span class="headline">Delivery</span>
        </v-card-title>
        <v-card-text>
          <v-container style="background-color: whitesmoke">
            <v-form ref="CustomerForm">
              <v-row>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-checkbox
                    color="secondary"
                    v-model="checkbox"
                    @change="fillCustomerDetails"
                    label="Autofill customer details"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="delivery.firstName"
                    label="First name"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="delivery.lastName"
                    label="Last name"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="delivery.telephone"
                    label="Phone"
                    clearable
                  />
                </v-col>
                <!-- <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="delivery.mobile"
                    label="Mobile"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="delivery.email"
                    label="Email"
                    clearable
                  />
                </v-col> -->
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="delivery.address"
                    label="Address"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="margin-bottom: -10%">
                  <v-text-field
                    v-model="delivery.city"
                    label="City"
                    clearable
                  />
                </v-col>
                <v-col cols="12" style="">
                  <v-text-field
                    v-model="delivery.state"
                    label="State"
                    clearable
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-row class="px-7">
          <v-btn color="secondary" dark tile @click="dialog = false">
            <v-card-text class="black--text font-weight-bold">
              {{ negativeBtn }}
            </v-card-text>
          </v-btn>
          <v-spacer />
          <v-btn color="primary" dark tile @click="addCustomer">
            <v-card-text class="black--text font-weight-bold">
              Add Delivery Details
            </v-card-text>
          </v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "delivery",
  props: ["show", "customer", "data", "negativeBtn"],
  data: () => ({
    customers: [],
    checkbox: false,
    delivery: {
      customerId: 0,
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      mobile: "",
      address: "",
      city: "",
      state: "",
    },
    defaultDelivery: {
      customerId: 0,
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      mobile: "",
      address: "",
      city: "",
      state: "",
    },
  }),

  computed: {
    dialog: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("close", val);
      },
    },
  },

  watch: {
    data(val) {
      this.delivery = Object.assign({}, val);
    },
  },

  methods: {
    addCustomer() {
      this.$emit("change", this.delivery);
      this.dialog = false;
    },
    fillCustomerDetails() {
      if (this.checkbox) {
        this.delivery = Object.assign({}, this.customer);
      } else {
        this.delivery = Object.assign({}, this.defaultDelivery);
      }
    },
  },
};
</script>