<template>
  <div class="review-container">
    <loading
      :active.sync="isLoading"
      color="#f8a229"
      loader="dots"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />
    <OrderNumber
      :show="orderNumberDialog"
      @close="
        orderNumberDialog = false;
        customerDialog = true;
      "
      @change="updateOrderNumber"
    />
    <Customer
      :show="customerDialog"
      :data="customer"
      :negativeBtn="isInvoicePrint ? 'Skip' : 'Close'"
      @close="
        customerDialog = false;
        isInvoicePrint ? (deliveryDialog = true) : '';
      "
      @change="updateCustomer"
    />
    <Delivery
      :show="deliveryDialog"
      :customer="customer"
      :data="delivery"
      :negativeBtn="isInvoicePrint ? 'Skip' : 'Close'"
      @close="
        deliveryDialog = false;
        isInvoicePrint ? invoicePrint() : '';
      "
      @change="updateDelivery"
    />
    <div style="display: none">
      <Invoice
        ref="invoiceContent"
        :bill="bill"
        :orderNumber="orderNumber"
        :customer="customer"
        :delivery="delivery"
        :company="company"
        :customerCredit="customerCredit"
        >Invoice</Invoice
      >
      <Bill
        ref="billContent"
        :bill="bill"
        :customer="customer"
        :company="company"
      />
    </div>

    <v-container class="row1">
      <v-row>
        <v-col cols="14" sm="4" md="2">
          <v-row dense class="">
            <router-link
              v-bind:to="'/admin/admin-bills'"
              style="text-decoration: none"
            >
              <v-btn color="secondary">
                <v-icon color="black"> mdi-arrow-left </v-icon>
              </v-btn>
            </router-link>
          </v-row></v-col
        >
        <v-col cols="14" sm="8" md="10">
          <v-row dense justify="end" class="mr-4">
            <router-link v-bind:to="'/home'" style="text-decoration: none">
              <v-btn color="secondary" dark @click="dialog = true">
                <v-icon color="black"> mdi-cart </v-icon>
                <v-card-text class="black--text font-weight-bold">
                  Add Order
                </v-card-text>
              </v-btn>
            </router-link>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <div class="products-section">
      <div class="product-list">
        <div v-for="(product, i) in bill.billProducts" :key="i">
          <div>
            <h2 class="product-name">
              {{
                product.productDescription +
                " (" +
                currencyFormat(product.amount) +
                (product.unitName ? "/" + product.unitName : "") +
                ")"
              }}
            </h2>
          </div>
          <div class="product">
            <img
              class="product-img"
              :src="`${resourceUrl + product.image}`"
              alt=""
            />
            <div class="product-img" v-if="!product.image">
              <v-icon class="text-h1"> mdi-image </v-icon>
              No Image
            </div>
            <!-- <div class="product-add-on-list">
              <h4
                v-if="product.billProductAddons.length > 0"
                class="product-sub-title mt-3 mb-3"
              >
                ADDONS
              </h4>

              <div
                class="product-add-on"
                v-for="addon in product.billProductAddons"
                :key="addon.billProductAddonId"
              >
                <h3 class="product-add-on-title">
                  {{
                    addon.addonItemName +
                      " (" +
                      currencyFormat(addon.price) +
                      ")"
                  }}
                </h3>

                <div
                  class="close-icon-btn"
                  v-if="!isReview"
                  @click="removeAddon(product, addon)"
                >
                  <div style="margin-top: -6px">&times;</div>
                </div>
              </div>
              <h4
                v-if="product.billProductOptions.length > 0"
                class="product-sub-title mt-3 mb-3"
              >
                OPTIONS
              </h4>

              <div
                class="product-add-on"
                v-for="option in product.billProductOptions"
                :key="option.billProductOptionId"
              >
                <h3 class="product-add-on-title">
                  {{
                    option.optionItemName +
                      " (" +
                      currencyFormat(option.price) +
                      ")"
                  }}
                </h3>

                <div
                  class="close-icon-btn"
                  v-if="!isReview"
                  @click="removeOption(product, option)"
                >
                  <div style="margin-top: -6px">&times;</div>
                </div>
              </div>
              <h4
                v-if="product.instructions != ''"
                class="product-sub-title mt-3 mb-3"
              >
                INSTRUCTIONS
              </h4>

              <div class="product-add-on">
                <h3 class="product-add-on-title">
                  {{ product.instructions }}
                </h3>
              </div>
            </div> -->
            <div class="product-qty-price-section">
              <div class="product-qty-section">
                <!-- <h3 class="product-qty-title">QTY</h3> -->
                <div class="product-qty-value-changer">
                  <div
                    class="increment-icon-btn"
                    v-if="!isReview"
                    @click="increaseQty(product)"
                  >
                    &and;
                  </div>
                  <h2 class="product-qty-value">
                    {{
                      (product.unitQty && product.unitQty > 0
                        ? product.quantity / product.unitQty
                        : product.quantity) +
                      " " +
                      product.unitName
                    }}
                  </h2>
                  <div
                    class="decrement-icon-btn"
                    v-if="!isReview"
                    @click="decreaseQty(product)"
                  >
                    &or;
                  </div>
                </div>
              </div>
              <h2 class="product-price">
                {{
                  currencyFormat(
                    product.unitQty && product.unitQty > 0
                      ? (product.quantity / product.unitQty) * product.amount
                      : product.quantity * product.amount
                  )
                }}
              </h2>
            </div>
            <div
              class="product-remove-icon"
              v-if="!isReview"
              @click="removeProduct(product)"
            >
              &times;
            </div>
            <div class="mobile-qty-price-remove">
              <div class="mobile-product-qty-section">
                <!-- <h3 class="mobile-product-qty-title">QTY</h3> -->
                <div class="mobile-product-qty-value-changer">
                  <div
                    class="mobile-decrement-icon-btn"
                    v-if="!isReview"
                    @click="decreaseQty(product)"
                  >
                    <v-icon color="" class=""> mdi-minus-circle </v-icon>
                  </div>
                  <h2 class="mobile-product-qty-value">
                    {{
                      (product.unitQty && product.unitQty > 0
                        ? product.quantity / product.unitQty
                        : product.quantity) +
                      " " +
                      product.unitName
                    }}
                  </h2>
                  <div
                    class="mobile-increment-icon-btn"
                    v-if="!isReview"
                    @click="increaseQty(product)"
                  >
                    <v-icon color="" class=""> mdi-plus-circle </v-icon>
                  </div>
                </div>
              </div>
              <h2 class="mobile-product-price">
                {{ currencyFormat(product.actualPrice) }}
              </h2>
              <div
                class="mobile-product-remove-icon"
                v-if="!isReview"
                @click="removeProduct(item)"
              >
                &times;
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="payment-section">
      <div>
        <div class="payment-list">
          <div class="print-menu">
            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="print-btn"
                  @click="
                    orderNumberDialog = true;
                    isInvoicePrint = true;
                  "
                >
                  <v-icon color="black" class=""> mdi-file-download </v-icon>
                  <v-icon color="black" class=""> mdi-printer </v-icon>
                </div>
              </template>
              <span>Invoice</span>
            </v-tooltip>

            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="print-btn"
                  @click="customerDialog = true"
                >
                  <v-icon color="black" class="">
                    mdi-account-details-outline
                  </v-icon>
                </div>
              </template>
              <span>Customer Details</span>
            </v-tooltip>

            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="print-btn"
                  @click="deliveryDialog = true"
                >
                  <v-icon color="black" class="">
                    mdi-truck-delivery-outline
                  </v-icon>
                </div>
              </template>
              <span>Deliver to</span>
            </v-tooltip>

            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="print-btn"
                  @click="$webSerialPrinterApi.print(bill, company)"
                >
                  <v-icon color="black" class=""> mdi-printer-pos </v-icon>
                </div>
              </template>
              <span>Receipt Printer</span>
            </v-tooltip>

            <v-tooltip top color="black">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="print-btn"
                  @click="billPrint"
                >
                  <v-icon color="black" class="">
                    mdi-receipt-text-outline
                  </v-icon>
                  <v-icon color="black" class=""> mdi-printer </v-icon>
                </div>
              </template>
              <span>Receipt</span>
            </v-tooltip>
          </div>
          <div class="payment-group mt-3">
            <p>SUB TOTAL</p>
            <p>{{ currencyFormat(amount) }}</p>
          </div>
          <div class="payment-group" v-if="bill.extraCharges > 0">
            <p>EXTRA CHARGES</p>

            <p>{{ currencyFormat(bill.extraCharges) }}</p>
          </div>

          <div class="payment-group" v-if="bill.deliveryCharge > 0">
            <p>DELIVERY CHARGES</p>
            <p>{{ currencyFormat(bill.deliveryCharge) }}</p>
          </div>
          <div
            class="payment-group"
            v-if="
              bill.discountTotal &&
              parseFloat(bill.discountTotal).toFixed(2) > 0
            "
          >
            <p>DISCOUNT</p>
            <p>{{ currencyFormat(bill.discountTotal) }}</p>
          </div>
          <div class="payment-group">
            <h3 class="payment-name">TOTAL</h3>
            <h3 class="payment-price">{{ currencyFormat(total) }}</h3>
          </div>

          <div class="payment-btn-section">
            <div
              class="pay-btn black--text font-weight-bold"
              @click="addPayment()"
            >
              PAY HERE
              <v-icon color="black" class="ml-2">
                mdi-credit-card-outline
              </v-icon>
            </div>

            <div class="pay-info black--text font-weight-bold">
              Payment Information
              <v-icon color="black" class="ml-2">
                mdi-arrow-down-thin-circle-outline
              </v-icon>
            </div>

            <div>
              <tr>
                <v-chip
                  label
                  color="primary"
                  class="white--text mt-2"
                  style="
                    justify-content: right;
                    text-transform: uppercase;
                    font-weight: bold;
                  "
                  v-if="totalPaid == bill.billTotal"
                >
                  Paid

                  <v-icon class="ml-2" color="white"> mdi-check-circle </v-icon>
                </v-chip>
                <v-chip
                  label
                  color="red"
                  class="white--text mt-2"
                  style="
                    justify-content: right;
                    text-transform: uppercase;
                    font-weight: bold;
                  "
                  v-if="totalPaid > 0 && totalPaid != bill.billTotal"
                >
                  Not Paid
                  <v-icon class="ml-2" color="white"> mdi-close-circle </v-icon>
                </v-chip>

                <div>
                  <v-chip
                    v-for="(payment, index) in payments"
                    :key="index"
                    label
                    color="secondary"
                    outlined
                    class="black--text mb-2 mt-2 mr-2"
                    style="
                      font-size: 12px;
                      width: auto;
                      justify-content: right;
                      text-transform: uppercase;
                      font-weight: bold;
                    "
                  >
                    <div
                      :style="[
                        payment.payMethod == 'credit' ? { color: 'red' } : {},
                      ]"
                    >
                      {{ payment.payMethod }} :
                      {{ currencyFormat(payment.payAmount) }}
                    </div>
                  </v-chip>
                </div>

                <v-chip
                  label
                  color="secondary"
                  outlined
                  class="black--text mt-2"
                  v-if="payments.length == 0"
                >
                  No Payments
                </v-chip>
              </tr>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="paymentView" max-width="500px" origin="top right">
      <v-card>
        <v-row style="float: right; margin-top: 10px; margin-right: 10px">
          <div>
            <v-icon color="closeButton" @click="paymentView = false">
              mdi-close-circle
            </v-icon>
          </div>
        </v-row>

        <v-card-title>
          <span class="headline"> Payment</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="billPaymentForm">
              <v-row>
                <v-col cols="12" class="mb-n10">
                  <v-text-field
                    readonly
                    v-model="billPayment.billNo"
                    label="Bill No"
                    outlined
                  />
                </v-col>

                <v-col v-if="bill.customerId != null" cols="12" class="mb-n10">
                  <v-select
                    v-model="billPayment.payMethod"
                    label="Payment Method"
                    :items="payMethods"
                    item-text="text"
                    item-value="value"
                    outlined
                  />
                </v-col>

                <v-col v-if="bill.customerId == null" cols="12" class="mb-n10">
                  <v-select
                    v-model="billPayment.payMethod"
                    label="Payment Method"
                    :items="payMethodsWithoutCard"
                    item-text="text"
                    item-value="value"
                    outlined
                  />
                </v-col>

                <v-col cols="12" class="mb-n10">
                  <v-text-field
                    v-model="billPayment.payAmount"
                    label="Total"
                    outlined
                    clearable
                    type="number"
                    class="numberInput"
                  />
                </v-col>
                <v-col
                  cols="12"
                  v-if="billPayment.payMethod == 'cash'"
                  class="mb-n10"
                >
                  <v-text-field
                    v-model="billPayment.tendered"
                    label="Paid Amount"
                    outlined
                    clearable
                    type="number"
                    class="numberInput"
                  />
                </v-col>

                <v-col
                  cols="12"
                  v-if="billPayment.payMethod == 'cash'"
                  class="mb-n10"
                >
                  <v-text-field
                    v-model="balanceAmount"
                    label="Balance Amount"
                    outlined
                    clearable
                    type="number"
                    class="numberInput"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="paymentView = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="validate"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Bill from "./components/Bill.vue";
import Invoice from "./components/Invoice.vue";
import Customer from "./components/Customer.vue";
import Delivery from "./components/Delivery.vue";
import OrderNumber from "./components/OrderNumber.vue";
import { fa } from "vuetify/lib/locale";
export default {
  components: {
    Loading,
    html2canvas,
    Bill,
    Invoice,
    Customer,
    Delivery,
    OrderNumber,
  },
  name: "OrderReview",
  data: () => ({
    isLoading: false,
    loadingSize: 50,
    loadingFullPage: true,
    loading: false,
    orderNumberDialog: false,
    orderNumber: 0,
    customerDialog: false,
    deliveryDialog: false,
    isInvoicePrint: false,
    taxPercentage: 10,
    bill: {},
    customer: {
      customerId: 0,
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      mobile: "",
      address: "",
      city: "",
      state: "",
    },
    customerCredit: {
      amount: 0,
      invoice: "",
      settledAmount: 0,
      settledAt: "",
    },

    delivery: {
      customerId: 0,
      firstName: "",
      lastName: "",
      telephone: "",
      email: "",
      mobile: "",
      address: "",
      city: "",
      state: "",
    },
    company: {
      companyName: "",
      abn: "",
      address: "",
      phoneNumber: "",
    },
    paymentView: false,
    billPayment: {
      billPaymentNo: 0,
      billNo: 0,
      payMethod: "card",
      payAmount: 0,
      tendered: 0,
    },

    payMethods: [
      { text: "Card ", value: "card" },
      { text: "Cash ", value: "cash" },
      { text: "Credit ", value: "credit" },
      { text: "Cheque ", value: "cheque" },
      { text: "Voucher ", value: "voucher" },
    ],
    payMethodsWithoutCard: [
      { text: "Card ", value: "card" },
      { text: "Cash ", value: "cash" },
      { text: "Cheque ", value: "cheque" },
      { text: "Voucher ", value: "voucher" },
    ],

    payments: [],
    amountPaid: 0,
    totalPaid: 0,
  }),
  watch: {
    payMethodFinal() {
      // console.log("Changed");
      this.balanceAmount = "";
      this.billPayment.tendered = "";
    },
  },

  computed: {
    balanceAmount() {
      var balance =
        parseFloat(this.billPayment.tendered) -
        parseFloat(this.billPayment.payAmount);

      return this.formatPrice(balance);
    },
    payMethodFinal() {
      return this.billPayment.payMethod;
    },
    isReview() {
      return this.billNumber > 0 ? true : false;
    },
    amount() {
      let amount = 0;
      if (this.bill && this.bill.billProducts) {
        amount = this.bill.billProducts.reduce((total, x) => {
          total +=
            x.amount *
            (x.unitQty && x.unitQty > 0 ? x.quantity / x.unitQty : x.quantity);
          return total;
        }, 0);
      }
      return amount;
    },
    subTotal() {
      return this.isReview ? this.bill.billTotalWithOutVat : 0;
    },
    tax() {
      return this.isReview
        ? this.bill.vatTotal
        : this.subTotal * (this.taxPercentage / 100);
    },
    serviceCharge() {
      return 0;
    },
    total() {
      return this.isReview
        ? this.bill.billTotal
        : this.subTotal + this.tax + this.serviceCharge;
    },
    billNumber() {
      return this.$route.params.id;
    },
    resourceUrl() {
      return this.$http.defaults.baseURL.replace("/api", "");
    },
  },
  created() {
    this.getData();
    this.$http.get("Licensee/CompanyInfo").then((res) => {
      // console.log("res", res);
      this.company = Object.assign({}, res.data);
    });
  },
  methods: {
    invoicePrint() {
      this.isInvoicePrint = false;

      var doc = new jsPDF("p", "pt", "a4");
      doc.html(this.$refs.invoiceContent.$el, {
        callback: function (doc) {
          doc.autoPrint();
          doc.output("dataurlnewwindow");
        },
        margin: [5, 5, 5, 5],
        x: 20,
        y: 10,
        html2canvas: {
          scale: 0.5,
          width: 1000,
        },
      });
    },
    billPrint() {
      // this.report();
      var doc = new jsPDF("p", "pt", "a4");
      doc.html(this.$refs.billContent.$el, {
        callback: function (doc) {
          doc.autoPrint();
          doc.output("dataurlnewwindow");
        },
        margin: [5, 5, 5, 5],
        x: 20,
        y: 10,
        html2canvas: {
          scale: 0.5,
          width: 1000,
        },
      });
    },

    addPayment() {
      // console.log("this.bill", this.bill);

      //  console.log("this.bill",item);
      this.amountPaid = 0;
      this.isLoading = true;
      this.billPayment.billNo = this.bill.billNo;
      this.billPayment.billPaymentNo = this.bill.billNo;
      this.billPayment.customerId = this.bill.customerId;

      this.$http
        .get(`/BillPayment/GetByBillNo/${this.bill.billNo}`)
        .then((response) => {
          this.payments = response.data.data;
          //  console.log("Payment", this.payments);

          for (var i = 0; this.payments.length > i; i++) {
            this.amountPaid = this.amountPaid + this.payments[i].payAmount;
          }

          if (this.payments.length > 0) {
            this.billPayment.payAmount = this.formatPrice(
              this.bill.billTotal - this.amountPaid
            );
          } else {
            this.billPayment.payAmount = this.formatPrice(this.bill.billTotal);
          }
          this.isLoading = false;
          this.paymentView = true;
        });
    },

    validate() {
      if (this.billPayment.payAmount > 0) {
        this.save();
      } else {
        this.$toast.error("Enter the valid amount", "Error", {
          position: "topRight",
        });
      }

      // console.log("Bill Payment", this.billPayment);
    },
    save() {
      this.isLoading = true;
      this.billPayment.tendered = this.billPayment.tendered || 0;
      this.$http
        .post("/BillPayment", this.billPayment)
        .then((response) => {
          if (response.data.success === true) {
            // this.addOnItems.unshift(response.data.data);

            // console.log("Payment", response);
            this.paymentView = false;
            this.isLoading = false;
            this.$toast.success("Payment added successfully.", "Success", {
              position: "topRight",
            });
            this.getData();
          } else {
            this.isLoading = false;
            this.$toast.error(response.data.message, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$toast.error("Payment Process Failed", "Error", {
              position: "topRight",
            });
          }
        });
      if (this.billPayment.payMethod === "cash") {
        this.$webSerialPrinterApi.cashDrawerKick();
      }
    },

    getData() {
      this.isLoading = true;
      this.$http
        .get(`BillHeader/BillNo/${this.billNumber}`)
        .then((response) => {
          if (response.data.success) {
            this.bill = response.data.data;
            this.setDelivery(this.bill);
            this.$http.get(`Customers/${this.bill.customerId}`).then((res) => {
              if (res.data.success && res.data.data) {
                this.customer = res.data.data;
              }
            });
            this.$http
              .get(`CustomerCredit/${this.bill.customerId}/Amount`)
              .then((res) => {
                this.customerCredit.amount = res.data.data;
              });
            this.$http
              .post(
                `CustomerCredit/${this.bill.customerId}/CreditDetails/Settled`,
                {
                  pageNumber: 1,
                  pageSize: 1,
                  isSettled: true,
                  customerId: this.bill.customerId,
                }
              )
              .then((res) => {
                let detail = res.data.data.items;
                if (detail && detail.length > 0) {
                  this.customerCredit.settledAmount = detail[0].settledAmount;
                  this.customerCredit.settledAt = detail[0].createdAt;
                  this.customerCredit.invoice = detail[0].billNo;
                }
              });
            this.isLoading = false;
          }
        });

      this.totalPaid = 0;
      this.$http
        .get(`/BillPayment/GetByBillNo/${this.billNumber}`)
        .then((response) => {
          this.payments = response.data.data;
          for (var i = 0; this.payments.length > i; i++) {
            //  console.log(this.payments[i].payAmount);
            this.totalPaid = this.totalPaid + this.payments[i].payAmount;
          }
        });
    },
    setDelivery(val) {
      if (val) {
        var data = {};
        if (val.deliveryAddress) {
          let addressData = val.deliveryAddress.split(",");
          data.address =
            (addressData && addressData.length > 0
              ? addressData[0].trim() + ", "
              : "") +
            (addressData && addressData.length > 1
              ? addressData[1].trim()
              : "");
          data.city =
            addressData && addressData.length > 2 ? addressData[2].trim() : "";
          data.state =
            addressData && addressData.length > 3 ? addressData[3].trim() : "";
        }
        data.telephone = val.deliveryContactNo;
        if (val.deliveryBoyName) {
          let names = val.deliveryBoyName.split(",");
          data.firstName = names && names.length > 0 ? names[0] : "";
          data.lastName = names && names.length > 1 ? names[1] : "";
        }

        this.delivery = Object.assign({}, data);
      }
    },
    removeAddon(product, addon) {
      let index = product.addons.findIndex((x) => x.id == addon.id);
      product.addons.splice(index, 1);
    },
    increaseQty(product) {
      product.qty++;
    },
    decreaseQty(product) {
      product.qty--;
    },
    removeProduct(item) {
      let index = this.products.findIndex((x) => x.id == item.id);
      this.products.splice(index, 1);
    },
    pay() {},

    backToMenu() {
      this.$router.go(-1);
    },
    updateCustomer(customer) {
      this.customer = Object.assign({}, customer);
      let data = Object.assign({}, this.customer);
      data.customerUserId = data.userId;
      if (data.customerId > 0) {
        this.$http.put(`/Customers/${data.customerId}`, null, { params: data });
      } else {
        this.$http.post(`/Customers`, data);
      }

      this.bill.customerId = data.customerId;
      this.bill.custName = data.firstName + " " + data.lastName;
      let bill = Object.assign({}, this.bill);
      bill.billProducts = [];
      bill.status = data.orderStatus;
      this.$http.put(`/BillHeader/${bill.billHeaderId}`, bill);
    },
    updateDelivery(delivery) {
      this.delivery = Object.assign({}, delivery);
      let data = Object.assign({}, this.bill);
      data.billProducts = [];
      data.status = data.orderStatus;
      data.deliverCharge = data.deliveryCharge;
      data.deliveryAddress =
        delivery.address + ", " + delivery.city + ", " + delivery.state;
      data.deliveryBoyName = delivery.firstName + ", " + delivery.lastName;

      this.$http.put(`/BillHeader/${this.bill.billHeaderId}`, data);
    },
    updateOrderNumber(num) {
      this.orderNumber = num;
    },
    currencyFormat(val) {
      return val ? "$" + parseFloat(val).toFixed(2) : "$0.00";
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
:root {
  --main-bg-color: #f9f9f9;
  --primary-color: #80c042;
  --secondary-color: #e0e0e0;
}
.print-menu {
  display: flex;
  justify-content: space-between;
}
.print-btn {
  cursor: pointer;
  padding: 5px;
  border: 1px solid black;
  margin: 2px;
}
.main-bg {
  background-color: var(--main-bg-color);
}
.primary-bg {
  background-color: var(--primary-color);
}
.secondary-bg {
  background-color: var(--secondary-color);
}
.white-bg {
  background-color: #fff;
}

.primary-text {
  color: var(--primary-color);
}
.secondary-text {
  color: var(--secondary-color);
}

.h1 {
  font-size: 2em;
  font-weight: 500;
}
.h2 {
  font-size: 1.8em;
  font-weight: 500;
}
.h3 {
  font-size: 1.5em;
  font-weight: 500;
}
.h4 {
  font-size: 1.2em;
  font-weight: 500;
}
.h5 {
  font-size: 1em;
  font-weight: 500;
}
.h6 {
  font-size: 0.8em;
  font-weight: 500;
}

.w-400 {
  font-weight: 400;
}
.w-500 {
  font-weight: 500;
}
.w-600 {
  font-weight: 600;
}

.row {
  display: flex;
  flex-wrap: wrap;
}
.center {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}

.btn {
  flex: auto;
  text-align: center;
  padding: 15px;
  margin: 3px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.btn:hover,
.btn:focus {
  opacity: 0.8;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.review-container {
  display: flex;
  flex-wrap: wrap;
}
.products-section {
  width: 50%;
  border-radius: 10px;
  background-color: var(--main-bg-color);
}
.page-title-group {
  display: flex;
  align-items: center;
}
.page-title {
  font-size: 2em;
  font-weight: 500;
  color: #75787e;
  padding: 10px 25px;
}
.mobile-back-menu-icon {
  display: none;
}
.product-list {
  margin-top: 2%;
}
.product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background: #fff;
  margin: 10px 15px;
  border-radius: 0px 0px 10px 10px;
}
.product-img {
  width: 150px;
  height: 100px;
  object-fit: cover;
  border-radius: 0 25px 0 25px;
}
.product-add-on-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
  max-width: 35%;
}
.product-name {
  padding: 15px 25px;
  background: #fff;
  margin: 10px 15px;
  margin-bottom: -25px;
  border-radius: 10px 10px 0px 0px;
  font-size: 1.2em;
  font-weight: 500;
}
.product-add-on {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.product-add-on-title {
  font-size: 0.8em;
  font-weight: 600;
  padding: 0 5px;
  margin: 5px 0;
}
.close-icon-btn {
  background: #cdcdcd;
  border-radius: 25px;
  padding: 0 2.5px;
  height: 18px;
  font-size: 1.2em;
  font-weight: 500;
  cursor: pointer;
}
.close-icon-btn:hover,
.close-icon-btn:focus {
  opacity: 0.8;
}
.mobile-qty-price-remove {
  display: none;
}
.product-qty-price-section {
  background: #eeeeee;
  padding: 2%;
  display: flex;
  border-radius: 10px;
}
.product-qty-section {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.product-qty-title {
  font-size: 1em;
  font-weight: 600;
}
.product-qty-value-changer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  /* padding-left: 15px; */
}
.increment-icon-btn {
  font-size: 1em;
  font-weight: 600;
  color: #6a6c70;
  cursor: pointer;
}
.increment-icon-btn:hover,
.increment-icon-btn:focus {
  opacity: 0.8;
}
.product-qty-value {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--primary-color);
}
.decrement-icon-btn {
  font-size: 1em;
  font-weight: 600;
  color: #6a6c70;
  cursor: pointer;
}
.decrement-icon-btn:hover,
.decrement-icon-btn:focus {
  opacity: 0.8;
}
.product-price {
  font-size: 1.3em;
  font-weight: 600;
  margin-left: 20px;
}
.product-remove-icon {
  padding: 0px 12px 2px 12px;
  background-color: #cdcdcd;
  border-radius: 50%;
  font-size: 1.8em;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  margin-left: 20px;
}
.product-remove-icon:hover,
.product-remove-icon:focus {
  opacity: 0.8;
}
.payment-section {
  width: 50%;
  margin-top: -10px;
  padding: 0 25px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}
.back-to-menu-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1em;
  font-weight: 600;
  color: #adadad;
  cursor: pointer;
}
.back-to-menu-btn:hover,
.back-to-menu-btn:active {
  opacity: 0.8;
}
.back-menu-text {
  margin-top: 1.5px;
  margin-left: 15px;
}
.payment-list {
  background-color: #f9f9f9;
  border-radius: 10px;
  width: 100%;
  padding: 25px;
  margin-top: 25px;
}
.payment-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.payment-name {
  font-size: 1.2em;
  font-weight: 500;
  color: #535457;
}
.payment-price {
  font-size: 1.3em;
  font-weight: 500;
  color: #535457;
}
.total-payment-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 25px 35px 0;
}
.payment-total {
  font-size: 1.8em;
  font-weight: 500;
  color: #535457;
}
.payment-total-price {
  font-size: 1.8em;
  font-weight: 500;
}
.pay-btn {
  background-color: #f8a229;
  text-align: center;
  padding: 7.5px;
  margin-bottom: 15px;
  font-size: 1em;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}
.pay-info {
  border-color: #30b72a;
  border: 1px solid;
  text-align: center;
  padding: 7.5px;
  font-size: 1em;
  color: #fff;
  border-radius: 5px;
}

.pay-btn:hover,
.pay-btn:focus {
  background-color: #f8a229;
  opacity: 0.8;
}
.coupon-btn {
  font-size: 1.3em;
  font-weight: 500;
  color: #535457;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.coupon-btn:hover,
.coupon-btn:active {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    min-height: auto;
  }
  .page-title-group {
    margin-left: 10px;
  }
  .page-title {
    font-size: 1.5em;
  }
  .products-section {
    width: 100%;
  }
  .payment-section {
    width: 100%;
    padding: 25px;
  }
  .back-to-menu-btn {
    display: none;
  }
  .mobile-back-menu-icon {
    display: block;
    font-size: 2em;
    margin-top: -3.5px;
    padding-left: 15px;
    color: #75787e;
  }
  .mobile-back-menu-icon:hover,
  .mobile-back-menu-icon:active {
    opacity: 0.8;
  }
  .product {
    flex-direction: column;
  }
  .product-img {
    max-width: 100%;
  }
  .product-add-on-list {
    max-width: 100%;
    margin: 15px 0;
  }
  .product-name {
    text-align: center;
    font-size: 1.4em;
  }
  .product-add-on {
    padding: 7.5px 0;
  }
  .product-add-on-title {
    font-size: 1em;
    font-weight: 500;
  }
  .close-icon-btn {
    font-size: 1.5em;
  }
  .product-qty-section {
    display: none;
  }
  .product-price {
    display: none;
  }
  .product-remove-icon {
    display: none;
  }
  .mobile-qty-price-remove {
    background: #eeeeee;
    padding: 0% 25% 0% 25%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
  }
  .mobile-product-qty-section {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
  .product-qty-price-section {
    display: none;
  }
  .mobile-product-qty-title {
    font-size: 1em;
    font-weight: 600;
    margin-right: 10px;
  }
  .mobile-product-qty-value-changer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .mobile-increment-icon-btn {
    font-size: 1.5em;
    font-weight: 600;
    color: #6a6c70;
    cursor: pointer;
  }
  .mobile-increment-icon-btn:hover,
  .mobile-increment-icon-btn:focus {
    opacity: 0.8;
  }
  .mobile-product-qty-value {
    font-size: 1.2em;
    font-weight: 600;
    color: var(--primary-color);
    margin: -8px 10px -5px;
  }
  .mobile-decrement-icon-btn {
    font-size: 1.5em;
    font-weight: 600;
    color: #6a6c70;
    cursor: pointer;
  }
  .mobile-decrement-icon-btn:hover,
  .mobile-decrement-icon-btn:focus {
    opacity: 0.8;
  }
  .mobile-product-price {
    font-size: 1.3em;
    font-weight: 600;
    margin-left: 20px;
  }
  .mobile-product-remove-icon {
    padding: 0px 12px 2px 12px;
    background-color: #cdcdcd;
    border-radius: 50%;
    font-size: 1.8em;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    margin-left: 20px;
  }
}
</style>
